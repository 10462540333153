@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

@import './_variables.sass';

@import '../node_modules/bulma/bulma.sass';

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

